// Override default variables before the import

$headers-and-buttons: "Sato", "Red Hat Display", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fc-dark-blue: #091454;
$fc-blue: #515EA8;
$fc-iris-blue: #5D5FEF;
$fc-light-blue2: #D2DDEC;
$fc-light-blue: #EAF3FF;
$fc-lighter-blue: #eef0f4;
$fc-lighter-blue2: #f3f8ff;
$fc-bg-green: #D5E3E0;
$fc-settings-green: #17A083;
$fc-green: #2fc6a5;
$fc-bright-light-green: #A5F3E3;
$fc-pink: #EBB4A9;
$fc-error-pink: #B75C4A;
$fc-background-gray: #F9F9F9;
$fc-dark-gray: #C4C4C4;
$fc-dark-gray2: #d1d1d1;
$fc-dark-gray-text: #767676;
$fc-gray: rgba(0, 0, 0, 0.6);
$fc-light-gray: #f4f4f4;
$fc-darker-gray: #333232;
$fc-background-blue: #D2DDEC;
$fc-border-gray: #E4E4E4;
$fc-white: #fff;

$fc-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);

// Schedule creation
$fc-schedule-dark-gray: #343434;
$fc-schedule-light-gray: #f2f2f2;
$fc-schedule-light-gray2: #f6f6f6;
$fc-schedule-gray: #e8e8e8;
$fc-schedule-red: #a55528;
$fc-schedule-pink: #ffeee4;
$fc-schedule-dark-gray2: #545454;

$font-family-base: "Red Hat Display", "Helvetica Neue", Helvetica, Arial, sans-serif;

$theme-colors: (
  "primary": $fc-dark-blue,
  "secondary": $fc-blue,
  "danger": $fc-error-pink,
  "success": $fc-bright-light-green,
  "info": $fc-light-blue,
  "light": $fc-dark-gray2,
  "dark": $fc-darker-gray
);

$alert-bg-level:0;
$alert-border-level:1;
$alert-color-level: 10;

$h5-font-size: 24px;
$h6-font-size: 20px;

@import "~bootstrap/scss/functions";