
@media(max-width: 991px){
    .host-banner {
        .avatar {
            margin: 1rem 0rem;
        }
        .host-actions {
            margin-top: 2rem;
        }
    }
}

@media(min-width: 992px) {
    .host-banner {
        .host-actions {
            margin-top: 1.5rem;
        }
    }
}