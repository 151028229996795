.title-input:focus {
  border: none;
  outline: none;
}
.title-input-box:focus-within {
  outline: 1px solid #091454;
  ;
}
.modal-content {
  padding: 16px;
}