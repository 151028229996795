@import '../../custom-bootstrap-variables.scss';

.alert.top-banner {
    padding: 40px 20px;
    h5 {
        color: $fc-dark-blue;
    }
    color: $fc-blue;
    .btn.btn-link {
        color: $fc-blue;
        vertical-align: baseline;
    }
}

.flex-banner {
    .small-screen-title, .large-screen-title {
        margin-bottom: 1rem;
    }
    @media (min-width: 992px){
        text-align: left;
        .banner-left {
            float: left;
            margin-right: 12px;
        }
        .banner-right {
            float: right;
        }
        .small-screen-title {
            display: none;
        }
        .large-screen-title {
            display: block;
        }
    }
    @media(max-width: 991px){
        text-align: center;
        .large-screen-title {
            display: none;
        }
    }
}
