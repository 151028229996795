@import '../../../custom-bootstrap-variables.scss';
@import '../../../shared/components/task-list.scss';
.tile {
    border-radius: 8px;
    flex-basis: 355px;
    @media(max-width: 674px) {
      flex-basis: 100px;
    }
}

.tile.small {
  width: 200px;
  margin: 0 10px;
  position: relative;
}

@media(max-width: 375px) {
  .tile.large {
   border-radius: 4px;
  }
  .tile video {
    border-radius: 4px;
  }
  .tile .background {
    border-radius: 4px;
  }
  .tile .user-overlay .tile-details {
    display: none;
  }
  .tile .corner {
    border-radius: 4px;
  }
}

.tile.large {
  position: relative;
  overflow: hidden;
}

.call .tile.large {
  max-width: 355px;
}

.tile .background { 
  background-color: theme-color('light');
  width: 100%;
  max-height: 300px !important;
  padding-top: 56.25%;
  border-radius: 8px;
}

.tile.large video {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
}

@media(min-width: 376px) {
  .tile .corner {
    border-radius: 8px;
  }
}

.tile video {
  border-radius: 8px;
}
.tile.small video {
  border-radius: 8px;
}

.tile.small .background {
  border-radius: 8px;
}

.tile .user-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  border: 3px solid theme-color('secondary');
  border-radius: 8px;
  color: theme-color('primary');
  padding: 16px;
  font-size: 16px;
  line-height: 18px;
  z-index: 5;
  white-space: pre-wrap;
  overflow-y: auto;
}

.tile .overlay {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 17px;
}

.tile .overlay .overlay-icons > svg {
  margin: 36px;
}

.tile .corner {
  position: absolute;
  color: #ffffff;
  margin: 0;
}

.tile .corner.left-corner {
  background: none;
  font-size: 16px;
  top: 6px;
  left: 6px;
  z-index: 4;
  .host-badge {
    background: theme-color("secondary");
    border-radius: 8px;
    padding: 4px 8px;
    height: 30px;
  }
}

.tile.active {
  border: 3px solid theme-color('success');
  border-radius: 8px;
  color: theme-color('primary');
}

.tile .corner.right-corner {
  background: rgba(255, 255, 255, 0.87);
  top: 6px;
  right: 10px;
  width: 24px;
  height: 24px;
  z-index: 6;
}

.btn.mute-btn  {
  padding: 0px;
  margin: 0px;
}