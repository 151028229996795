@import './custom-bootstrap-variables.scss';

.alert.alert-secondary {
  color: #fff;
}

.bg-navbar-white {
  background-color: $fc-white;
  color: $fc-dark-blue;
  box-shadow: $fc-shadow;
}

.bg-navbar-white.navbar-light .navbar-nav {
  color: $fc-dark-blue;
}

.bg-navbar-white.navbar-light .navbar-nav {
  color: darken($fc-dark-blue, 15%);
}

.navbar-branding-flowclub {
  padding-top: 2px!important;
}

.font-weight-light {
  color: $fc-gray;
}

.btn.btn-inline {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: right;
}

.btn {
  border-radius: 8px !important;
  font-weight: 500;
  text-transform: uppercase;
  font-family: $headers-and-buttons;
}

.btn.btn-link {
  text-transform: none;
  padding: 0px;
  border: none;
  vertical-align:baseline;
}

.btn.btn-menu {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 143% */
  letter-spacing: 0.25px;
  color: #000000;
  text-align: left;
  padding: 5px 0px;
}

.btn.btn-success {
  color: #fff !important;
  background-color: $fc-green !important;
  border-color: $fc-green !important;
  &:hover {
    color: #fff;
  }
}

.btn.btn-menu:hover {
  background-color: $fc-light-blue;
}

.btn.btn-primary:hover {
  color: $fc-dark-blue;
  background-color: #fff;
  border: 1px solid $fc-dark-blue;
}

.btn.btn-header {
  padding: 8px 16px;
  color: $fc-dark-blue;
  margin-right: 16px;
}

.badge {
  border-radius: 16px !important;
  padding: 6px 16px !important;
}

.modal-header {
  border-bottom: 0px !important;
}

.modal-footer {
  border-top: 0x !important;
}

.nav-dropdown .dropdown-menu {
  background-color: $fc-white;
  box-shadow: $fc-shadow;
}

.nav-dropdown .dropdown-toggle {
  line-height: 26px!important;
}

.nav-dropdown .dropdown-item {
  background-color: $fc-white;
  color: $fc-dark-blue;
}

.nav-dropdown .dropdown-item:hover {
  background-color: $fc-light-blue;
  color: darken($fc-dark-blue, 15%);
}

.nav-dropdown .dropdown-item:active {
  background-color: $fc-dark-blue;
  color: $fc-white;
}

.tooltip {
  background-color: #FCFCFC;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.tooltip > .tooltip-inner {
  background-color: #ffffff;
  color: theme-color('primary');
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 11px;
  border-radius: 8px;
}

.tooltip-arrow, .tooltip .arrow {
  display: none !important;
}

.tooltip > .arrow::before {
  color: #ffffff !important;
  border-top-color: #ffffff !important;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $headers-and-buttons;
}

.desktop-right-mobile-center {
  @media (min-width: 992px) {
    text-align: right;
  }

  @media(max-width: 991px) {
    text-align: center;
    margin-top: 10px;
  }
}

.desktop-left-mobile-center {
  @media (min-width: 992px) {
    text-align: left;
  }

  @media(max-width: 991px) {
    text-align: center;
    margin-top: 10px;
  }
}

.muted {
  color: rgba(0, 0, 0, 0.6);
}

.mobile-bottom {
  @media (max-width: 768px) {
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

@import "bootstrap";