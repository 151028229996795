@import './custom-bootstrap-variables.scss';

.modal.invite-modal .modal-content{
    background: $fc-white;
    border-radius: 16px;
    .modal-header {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        /* or 120% */
        letter-spacing: 0.15px;

        /* Text 87% */
        color: $fc-darker-gray;
    }
    .modal-body {
        padding: 0 32px 32px 32px;
    }
}



.coworkers {
    padding-top: 10px;
    padding-bottom: 10px;
}

.badge-list {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $fc-dark-blue;
    text-transform: capitalize;
}

.badge-img {
    width: 24px;
    height: 24px;
}

.badge-group {
    color: $fc-dark-blue;
    text-transform: capitalize;
}

/* If you're snooping into this, come join us to bring Flow.Club
 * to 1M more groups 😃 founders@flow.club
 */

.badge-a16z {
    color: #ed8b00;
}

.badge-animalz {
    color: #b89ee7;
}

.badge-b8ta {
    color: #282828;
}

.badge-bomf {
    color: #002648;
}

.badge-fwc {
    color: #000000;
}

.badge-rnkbdc {
    color: brown;
}

.badge-startx {
    color: #2daf60;
}

.badge-odf, .badge-odw {
    color: #305cea;
}

.badge-saa {
    color: #8c1515;
}

.badge-ptya {
    color: #dc5353;
}

.badge-spc {
    color: #DCA55E;
}

.badge-hustle-fund, .badge-redwood-school {
    color: #FFBF00;
}

.badge-softbank {
    color: #828588;
}

.badge-XX {
    color: #3077B8;
}

.badge-hn {
    color: #ff6600;
}

.badge-yc {
    color: #ff611a;
}

.badge-s21 {
    color: #ff611a;
}

.badge-group-1, .badge-group-2, .badge-group-3, .badge-group-4 {
    color: #ff611a;
}

.badge-ph {
    color: #cc4d29;
}

.badge-maker {
    color: #056d4e;
}

.badge-flow-inc {
    color: #6451c2;
}

.form-control.invite-textarea {
    padding: 8px;
    resize: none;
    border-radius: 8px;
}
