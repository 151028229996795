.options-menu {

    .btn-group, .btn-group-vertical {
        width: 100%;
    }

    .btn.btn-menu {
        padding: 0;
        display: flex;
        align-items: center;
        &:focus {
            box-shadow: none;
        }
    }

    .host-controls-section {
        margin-bottom: 8px;
        .btn.btn-menu {
            text-transform: capitalize;
        }
    }

    .more-menu-device-icon {
        width: 44px;
        height: 44px;
        flex-basis: 44px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .more-menu-button-text {
        flex-grow: 1;
        padding-left: 6px;
        padding-right: 6px;
        box-sizing: border-box;
        line-height: 135%;
        text-transform: none;
    }

    .more-menu-right-caret {
        height: 44px;
        width: 28px;
        box-sizing: border-box;
        padding: 15px 10px;
        flex-basis: 28px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .close.btn {
        border: none !important;
        background: none;
        transition: opacity 0.15s ease-in-out;
        &:hover {
            background: none;
            border: none;
        }
    }
}

.menu-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */
    letter-spacing: 0.4px;
  
    /* Text 60% */
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0.5rem;
  }

