.popover.host-popover {
    @media(max-width: 375px) {
        width: 98%
    }
    @media(min-width: 376px) {
        width: 520px;
        max-width: 520px;
    }
    background: #FCFCFC;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    letter-spacing: 0.25px;
    /* Text 87% */
    color: rgba(0, 0, 0, 0.87);
    .container {
        padding: 24px;
    }
    .host-name {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        /* or 120% */
        letter-spacing: 0.15px;
        color: #000000;
    }
    .host-music-image {
        border-radius: 4px;
        width: 62px;
        height: 62px;
        margin: 0px 8px;
    }
    .host-music-images .host-music-image:first-child {
        margin-left: 0px;
    }
    .host-music-titles {
        padding: 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        list-style: none;
        letter-spacing: 0.4px;
        /* Text 60% */
        color: rgba(0, 0, 0, 0.6);
    }
    .arrow {
        display: none;
    }
}