@import '../custom-bootstrap-styles.scss';

.App {
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: $fc-background-blue;
}

.FlowClub-Title {
  font-family: "Permanent Marker", "Sato", Helvetica, Arial, sans-serif!important;
}

.FlowClub-Login {
  background-color: #89d4cf;
  background-image: linear-gradient(315deg, #89d4cf 0%, #6e45e1 74%);
  min-height: 100vh;
}

/* This should probably just be a component that uses the LinkStyledText styling, but eh this is less work for now */
a {
  color: #4744C9;
  &:hover {
    color: #5D5FEF;
    text-decoration: unset;
  }
  &:active {
    color: #261E7D;
  }
}