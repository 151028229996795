.contains-task-list {
    list-style: none;
    margin-bottom: 0px;
    white-space:normal;
    input[type="checkbox"] {
        border: 1px solid $fc-blue;
        width: 1em;
        height: 1em;
        margin-top: .25em;
        vertical-align: top;
        background-position: 50%;
        background-size: contain;
        -webkit-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        border-radius: 4px;
        &:checked {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' strokeWidth='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
            background-color: $fc-blue;
        }
    }
}