// David, 11-18-2021: We're using React-bootstrap 4
// Check this file for some default variables that we overrode
@import '../custom-bootstrap-variables.scss';

.dark-blue { color: $fc-dark-blue; }

.plans-section {
  margin: 64px 0;
}

.section-header {
  margin-bottom: 32px;
  text-align: center;
}

.section-pricing {
  display: flex;
  justify-content: center;
  background: url(../images/plans-bkgd-wave.png) repeat-x 50% 100%;
  padding-bottom: 80px;
}

.pricing-pill {
  position: relative;
  border: 6px solid $fc-dark-blue;
  width: 220px;
  text-align: center;
  padding: 32px;
  border-radius: 6px;
  display: inline-block;
  background: white;

  button {
    margin-top: 20px;
    padding: 12px 20px;
  }

  .price {
    font-size: 1.25rem;
    font-weight: 800;
  }

  .pricing {
    margin-bottom: 20px;
  }
}

.pricing-pill + .pricing-pill {
  margin-left: 32px;
}

@media(max-width: 520px) {
  .pricing-pill + .pricing-pill {
    margin-top: 32px;
    margin-left: 0; 
  }

  .pricing-pill {
    display: block;
    width: 100%;
  }

  .pricing-pills {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.section-unlimited {
  overflow-x: hidden;
}

.section-faq {
  margin-left: auto;
  margin-right: auto;
}

.session-card {
  background: #fff;
  border: 2px solid #E4E4E4;
  border-radius: 12px;
  font-family: "Red Hat Display";
  color: rgb(33, 37, 41);
  font-size: 16px;
  padding: 16px;
  display: grid;
  min-width: 480px;
  grid-template-columns: 96px 64px 1fr;

  .session-card-time {
    font-weight: 800;
  }
  .session-card-avatar {
    background: #ccc;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    content: url("../images/david-t.jpg");
  }

  .session-card-meta {
    margin-left: 10px;
    .session-card-title {
      font-weight: 800;
    }

    .session-card-subtitle {
      color: rgba(0, 0, 0, .5);
    }

    .session-card-host {
      color: rgba(0, 0, 0, .5);
    }
  }
}

.session-card + .session-card {
  margin-left: 20px;
}

.session-cards {
  background: url(../images/plans-bkgd-unlimited.png) repeat-y;
  background-position: 50% 50%;
  background-size: 100%;
  position: relative;
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before, &::after {
    position: absolute;
    bottom: 0;
    width: 120px;
    height: 100%;
    z-index: 1;
    content: " ";
  }

  &::before {
    left: 0;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0));
  }

  &::after {
    right: 0;
    background: linear-gradient(-90deg, #fff, rgba(255, 255, 255, 0));
  }
}

.data-pills {
  text-align: center;
}

.data-pill {
  background: white;
  border: 10px solid $fc-dark-blue;
  color: $fc-dark-blue;
  display: inline-block;
  font-weight: 800;
  padding: 32px;
  margin: 0 12px 12px 0;
  width: 180px;
  text-align: center;
}

.section-community {
  position: relative;
  background: url(../images/plans-bkgd-line.png) no-repeat center 100%;
}

.section-community-content {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  height: 640px;

  .data-pill {
    border: 6px solid $fc-dark-blue;
    position: absolute;
  }
}

.data-pill-hosts {
  left: 10%;
}

.data-pill-countries {
  right: 25%;
  top: 33.33%;
}

.data-pill-sessions {
  left: 5%;
  bottom: 0;
  padding: 64px;
  width: 270px;
}


.data-pill-num {
  font-size: 34px;
  font-weight: 800;
}

.data-pill-label {
  text-transform: uppercase;
}

$fc-plans-avatar-size: 120px;

.data-avatar {
  width: $fc-plans-avatar-size;
  height: $fc-plans-avatar-size;
  background: $fc-dark-gray;
  border-radius: 50%;
  position: absolute;
}

.data-avatar-top-left {
  top: 20%;
  left: 20%;
  z-index: 11;
  content: url("../images/alex.jpg");
}

.data-avatar-mid {
  z-index: 12;
  right: 40%;
  top: 50%;
  content: url("../images/haley.jpg");
}

.data-avatar-bottom-right { 
  z-index: 13;
  right: 10%;
  bottom: 10%;
  content: url("../images/david-s.jpg");
}

.data-avatar-bottom-left {
  z-index: 14;
  left: 5%;
  bottom: - $fc-plans-avatar-size / 2;
  content: url("../images/irene.jpg");
}

@media(min-width: 1024px) {
  .data-pill-hosts { left: 10%; }
  .data-pill-sessions { left: 15%; }
  .data-avatar-bottom-left { left: 15% }
  .data-avatar-bottom-right { right: 10%; }

  .data-avatar-top-left {
    top: 15%;
    left: 25%;
  }

  .pricing-pill {
    width: 320px;
    text-align: center;
    padding: 48px;
  }
}

@media(max-width: 768px) {
  .data-avatar-top-left { left: 30%; }

  .data-pill-countries {
    right: 20%;
    top: 33.33%;
  }

}

@media(max-width: 425px) {
  .data-avatar-top-left { top: $fc-plans-avatar-size/2; left: 50%; }
  .data-avatar-bottom-right { display: none; }
  .data-avatar-mid { 
    right: 5%;
  }
  .data-pill-countries { 
    top: 30%;
    right: 5%;
  }

  .data-avatar {
    width: $fc-plans-avatar-size * .8;
    height: $fc-plans-avatar-size * .8;
  }

  .hide-sm { display: none; }
}