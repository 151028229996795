@import '../../custom-bootstrap-variables.scss';

$fcModalMaxWidth: 640px;

.fcModal {
  background: white;
  padding: 64px 32px 48px 32px;
  border-radius: 12px;
  max-width: $fcModalMaxWidth;
  z-index: 9040;
  position: fixed;
  left: 50%;
  top: 50%;
  transform : translate(-50%, -50%);
  box-shadow: 0 4px 16px rgba(0, 0, 0, .25);
  text-align: center;
  min-width: 480px;

  .fcModal-img {
    margin-bottom: 24px;
    max-width: 320px;
  }
}

.fcModal-close {
  position: absolute;
  right: 24px;
  top: 8px;
  color: #808080;
  font-weight: 800;
  font-size: 32px;

  &:hover {
    cursor: pointer;
    color: darken(#808080, 15%);
  }
}

.fcModal-underlay {
  position: fixed;
  z-index: 9001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .15);
}

.fcModal-lockout {
  .fcModal-underlay {
    background: rgba(0, 0, 0, .25);
    backdrop-filter: blur(2px);
  }
}

.fcModal--hide {
  display: none;
}

.fcModal-mit {
  margin-bottom: 24px;
  text-align: left;
  display: flex;
  justify-content: center;
}

.fcModal-mit-label {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 4px;
  padding-left: 16px;
  color: $fc-darker-gray;
}

.fcModal-mit-data {
  max-width: 320px;
  background: $fc-lighter-blue2;
  padding: 8px 16px;
  border-radius: 12px;
  max-height: 120px;
  overflow-y: hidden;
  font-size: 14px;
}

// Mobile
@media (max-width: 480px) {
  .fcModal {
    width: 98%;
    min-width: 280px;
    left: 1%;
    transform: translateY(-50%);

    .fcModal-img, .fcModal-mit-data {
      max-width: 180px;
    }

    h2 { // h2 -> h4
      font-size: 1.5rem!important;
      width: 100%;
    }
  }
}

// Tablet
@media (min-width: 480px) and (max-width: 768px) {
  .fcModal {
    min-width: 420px;

    .fcModal-img, .fcModal-mit-data {
      max-width: 220px;
      margin-bottom: 12px;
    }

    h2 { // h2 -> h3
      font-size: 1.75rem!important;
      width: 100%;
    }
  }
}