@import '../../custom-bootstrap-variables.scss';

.flex { display: flex; }
.justify-center { justify-content: center; }
.mt-16 { margin-top: 16px; }
.mt-32 { margin-top: 32px; }
.mb-24 { margin-bottom: 24px; }
.mb-32 { margin-bottom: 32px; }
.pl-20 { padding-left: 20px; }

.container-settings {
    padding: 48px 0 240px 0; //TODO: zero out in mobile breakpoint
}

.header-settings {
    margin-bottom: 32px;
}

.settings-card--md {
    width: 287px;
}


.UpdateInfoForm {
    margin-top: 20px;
    min-height: 80vh;
}

// TODO: responsive mobile view fix
.invite-a-friend {
    color: theme-color('primary');
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.form-group.required .form-label:after {
    content:"*";
    color: theme-color('danger');
}

.settings-success {
    color: $fc-settings-green;
    font-family: $headers-and-buttons;
    font-size: 20px;
    line-height: 24px;
}

.settings-default {
    color: $fc-dark-gray-text;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

table.settings-invite-table {
    thead th {
        border-top: 0px;
        border-bottom: 1px;
    }
}

.login-email {
  opacity: 0.6;
}