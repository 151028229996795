@import '../../../custom-bootstrap-variables.scss';

.mic-check-background {
  background: #FFFFFF;
  border-radius: 8px;
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  letter-spacing: 0.15px;

  .tile.large {
    margin: 15px 0 10px 0;
    line-height: 0;

    video {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@media(max-width: 375px) {
  .mic-check-background {
    padding: 8px;
  }
}

@media(min-width: 376px) {
  .mic-check-background {
    min-height: 360px;
    padding: 24px;
  }
}

.join-screen-check {
  font-size: 16px;
  line-height: 24px;
}

.join-screen-col {
  border-right: 1px solid rgba(0, 0, 0, 0.38);
}

.join-screen-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  letter-spacing: 0.4px;

  /* Text 60% */
  color: rgba(0, 0, 0, 0.6);
}

.join-screen-edit {
  position: absolute;
  right: 0px;
  top: 0px;
}