textarea.form-control.chat-input {
  position: absolute;
  bottom: 16px;
  margin: 10px 0;
  width: calc(100% - 32px);
  height: 48px;
  background: rgba(196, 196, 196, 0.24);
  border-radius: 6px;
  resize: none;
  border: none;
  font-size: 14px;
  padding: 14px 12px 13px;

  &::placeholder {
    color:rgba(0, 0, 0, 0.6);
  }
}

.inverted-caret {
  transform: scale(1,-1)
}