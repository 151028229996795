.options-menu .device-selector .btn.btn-menu {
    padding: 8px 0px;
}
.device-selector {
    .btn {
        text-transform: capitalize;
        .right-caret {
            opacity: 0.38;
            transition: opacity 0.15s ease-in-out;
        }
        &:hover {
            .right-caret {
                opacity: 1;
            }
        }
    }
    .join-screen-subtitle {
        .btn.btn-button {
            padding: 0;
            line-height: 0;
            border: none;
            border-radius: 0 !important;
            height: auto;
            padding: 10px;
            opacity: 0.38;
            transition: opacity 0.15s ease-in-out;
            &:hover {
                opacity: 1;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}