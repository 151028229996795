@import '../../custom-bootstrap-variables.scss';

.music-share-container {
  background: #FFFFFF;
  border-radius: 8px;
  font-size: 14px;
  color: #000000;

  .music-share {
    overflow-y: auto;
    height: calc(100% - 100px);
    position: relative;
    margin-bottom: 10px;
  }

  .music-share-footer {
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
    background: #ffffff;
    width: 100%;
    margin: 0px;
  }
}