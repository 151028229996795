@import '../custom-bootstrap-variables.scss';

.flowclub-logo {
    width: 64px;
    height: 64px;
}

img.small-club-leader, svg.small-club-leader {
    width: 64px;
    height: 64px;
    margin-bottom: 15px;
}

.loading-container {
    min-height: 80vh;
}

.flowclub-logo-text {
    background-color: $fc-dark-gray;
    font-size: 32px;
    color: #fff;
    padding: 10px;
}

.schedule-background {
    background-color: $fc-background-gray;
    border-top: 2px solid $fc-border-gray;
    border-bottom: 2px solid $fc-border-gray;
    @media (min-width: 768px) {
        padding: 20px;
    }
    @media (max-width: 767px) {
        padding: 5px;
        margin: 0px;
    }
}


.table-schedule {
    border: 0px;
    vertical-align: top;
    text-align: left;
    @media (max-width: 767px) {
        padding: 10px 1px;
        scroll-margin-top: 190px;
    }
    @media (min-width: 768px) {
        padding: 20px;
        scroll-margin-top: 165px;
    }
    .schedule-date {
        font-size: 20px;
        font-weight: bold;
        color: theme-color("primary");
        text-align: center;
    }
    
    .schedule-event {
        &.schedule-unbookable {
            background: $fc-light-gray;
            &:hover {
                background: $fc-light-gray;
            }
            .event-title, .schedule-subtitle, .schedule-time {
                color: rgba(0,0,0, 0.38);
            }
            img {
                opacity: .8
            }
            .button.disabled {
                opacity: 1;
                background: $fc-pink;
            }
        }
        padding: 16px;
        border-bottom: 2px solid $fc-border-gray;
        vertical-align: middle;
        cursor: pointer;
        &:hover {
            background: $fc-lighter-blue2;
        }
        &.no-link {
            cursor: default;
            &:hover {
                background: #fff;
            }
        }
        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom: 0px;
        }
    }
}

.schedule-detail {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1.5px;
}

.schedule-time {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000;
    font-weight: bold;
    vertical-align: middle;
}

.event-title {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

h1.schedule-h1 {
    font-size: 36px;
}

.nav.schedule-nav {
    display: flex;
    flex-wrap: nowrap;
}

.date-pills {
    font-size: 14px;
}

.date-pills.active {
    background: theme-color("primary") !important;
    border-radius: 16px !important;
    color: #fff !important;
    box-sizing: border-box;
    padding: 6px 16px !important;
}