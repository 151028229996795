@import '../../custom-bootstrap-variables.scss';

.badge.host-badge-pill {
    background-color: $fc-iris-blue;
    color: $fc-white;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: center;
    letter-spacing: 0.25px;
    padding: 4px 16px 4px 10px !important;

}

.host-badge-image {
    margin-right:  6px;
}